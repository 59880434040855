* {
  user-select: none;
}

html,
body {
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Doppio One', sans-serif;
}

.container-tech {
  position: relative;
}

.tech {
  z-index: 100;
  transition: transform 0.3s ease-in-out;
}

.tech:hover {
  transform: scale(1.3);
}